import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Title from 'components/title';
import Text from 'components/utilities/text';

import { Container } from './process.css';

const Process = ({ process }) => {
  return process.map((row, i) => {
    let { title, content, image } = row;
    if (i % 2) {
      return (
        <Container key={i}>
          <div className="d-flex flex-column mx-auto imgWrapper" style={{ width: "300px" }}>
            <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
          </div>
          <div className="content">
            <Title as="h2">{title}</Title>
            <Text as="p">{content}</Text>
          </div>
        </Container>
      )
    } else {
      return (
        <Container key={i}>
          <div className="content">
            <Title as="h2">{title}</Title>
            <Text as="p">{content}</Text>
          </div>
          <div className="d-flex flex-column mx-auto imgWrapper" style={{ width: "300px" }}>
            <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
          </div>
        </Container>
      )
    }
  })
}

Process.propTypes = {
  process: PropTypes.object.isRequired
};

export default Process;
