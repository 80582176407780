import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const TextStyle = styled.span`
  display: block;
  width: 650px;
  margin: 0 auto;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 28px;
  }
`;
