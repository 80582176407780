import React from 'react';
import PropTypes from 'prop-types';
import { TextStyle } from './text.css';

const Text = ({ children, as = 'p', className }) => {
  return (
    <TextStyle as={as} className={className}>
      {children}
    </TextStyle>
  );
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  // size: PropTypes.oneOf(['large']),
  className: PropTypes.string,
};

export default Text;
