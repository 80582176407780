import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 100px;
  grid-column-gap: 48px;
  align-items: center;
  margin: 0 auto;
  padding: 100px 0;
  max-width: 1140px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
    padding: 50px 0;
    margin-right: 24px;
    margin-left: 24px;
    div.content {
      order: 1;
    }
    div.imgWrapper {
      order: 2;
    }
  }

  /* div:nth-child(2) {
    order: 2;
  } */
`;
