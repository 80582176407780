import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Hero from 'components/hero';
import Cta from 'components/cta';
import Process from 'components/process';

const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.hero.title} />
    <Hero hero={data.aboutJson.hero} />
    <Process process={data.aboutJson.process} />
    <Cta cta={data.aboutJson.cta} />
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      hero {
        title
        title_mobile
        content {
          childMarkdownRemark {
            html
          }
        }
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      process {
        title
        content
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      cta {
        title
        content
        action
      }
    }
  }
`;
